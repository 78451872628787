<template>
  <div class="box">
    <h2>CSV 格式化工具</h2>

    <div class="sub-title-bar">输入</div>

    <el-form label-width="120px">
      <el-form-item label="分隔">
        <el-input v-model="input.separator"></el-input>
      </el-form-item>
      <el-form-item label="筛选字段">
        <el-input
          v-model="input.select"
          placeholder="把要选出的字段index (base=0) 填在此处，留空表示全选。如：'1,3,5,7,9'"
        ></el-input>
      </el-form-item>
    </el-form>

    <el-input
      class="input"
      v-model="input.text"
      type="textarea"
      :rows="10"
    ></el-input>

    <div class="sub-title-bar">输出</div>

    <el-form label-width="120px">
      <el-form-item label="前缀">
        <el-input v-model="output.prefix"></el-input>
      </el-form-item>
      <el-form-item label="分隔">
        <el-input v-model="output.separator"></el-input>
      </el-form-item>
      <el-form-item label="后缀">
        <el-input v-model="output.suffix"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="make"> 生成 </el-button>
      </el-form-item>
    </el-form>

    <el-input
      class="output"
      v-model="output.text"
      type="textarea"
      :rows="10"
      readonly
    ></el-input>
  </div>
</template>

<script>
////////////////////////////////////////////////////////////////////////////////

function Parser() {
  this.separator = ",";
  this.selector = { 9999: true };
}

Parser.prototype = {
  init(separator, selector) {
    if (separator == null || separator == "") {
      separator = ",";
    } else if (separator == "\\t") {
      separator = "\t";
    } else if (separator == "\\space") {
      separator = " ";
    }

    if (selector == null || selector == "") {
      selector = null;
    } else {
      selector = this.parseSelector(selector);
    }

    this.separator = separator;
    this.selector = selector;
  },

  parseSelector(str) {
    if (str == null) {
      return null;
    }
    let map = {};
    let array = str.split(",");
    for (var i in array) {
      let el = array[i];
      el = el.trim();
      map[el] = true;
    }
    return map;
  },

  parse(text) {
    let rows = text.replace("\r", "\n").split("\n");
    let items = [];
    for (var i in rows) {
      let item = this.parseItem(rows[i]);
      if (item == null) {
        continue;
      }
      items.push(item);
    }
    return items;
  },

  parseItem(row) {
    if (row == null) {
      return null;
    }
    row = row.trim();
    if (row == "") {
      return null;
    }
    let cells = row.split(this.separator);
    let item = [];
    for (var i in cells) {
      if (!this.acceptColumn(i)) {
        continue;
      }
      let value = cells[i];
      item.push(value);
    }
    return item;
  },

  acceptColumn(index) {
    let sel = this.selector;
    if (sel == null) {
      return true;
    }
    return sel[index];
  },
};

////////////////////////////////////////////////////////////////////////////////

function Builder() {
  this.prefix = "";
  this.suffix = "";
  this.sep = "";
}

Builder.prototype = {
  init(prefix, sep, suffix) {
    this.prefix = prefix;
    this.sep = sep;
    this.suffix = suffix;
  },

  format(table) {
    let text = "";
    for (var i in table) {
      var item = table[i];
      text = text + this.formatItem(item) + "\n";
    }
    return text;
  },

  formatItem(item) {
    let text = this.prefix;
    for (var i in item) {
      if (i > 0) {
        text += this.sep;
      }
      text += item[i];
    }
    return text + this.suffix;
  },
};

////////////////////////////////////////////////////////////////////////////////

export default {
  name: "csv-formatter",

  data() {
    return {
      input: {
        separator: ",",
        select: "",
        text: "",
      },
      output: {
        text: "",
        prefix: "(",
        separator: ",",
        suffix: ")",
      },
    };
  },

  methods: {
    make() {
      let parser = new Parser();
      let builder = new Builder();
      parser.init(this.input.separator, this.input.select);
      let table = parser.parse(this.input.text);
      builder.init(
        this.output.prefix,
        this.output.separator,
        this.output.suffix
      );
      this.output.text = builder.format(table);
    },
  },
};
</script>

<style scoped>
.box {
  box-shadow: 0px 0px 10px gray;
  padding: 10px;
  margin: 20px;
  border-radius: 5px;
}

.sub-title-bar {
  padding: 10px;
  background-color: gray;
  text-align: left;
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
